<template>
    <button class="custom-checkbox-component" type="button" @click="onClick" :class="{ pulsate: isPulsating }">
        <div :class="[{ active: value }, colorClass]"></div>
        <div class="checkbox-label"><slot></slot></div>
    </button>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        color: {
            type: String,
            default: '' // white
        },
        isPulsating: {
            type: Boolean,
            default: false
        },
        isLocked: {
            type: Boolean,
            default: false
        },
        stopPropagation: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick(e) {
            if (this.stopPropagation) {
                e.stopPropagation();
            }

            if (this.isLocked) {
                return;
            }
            const newValue = !this.value;
            this.$emit('input', newValue);
            this.$emit('change', newValue);
        }
    },
    computed: {
        colorClass() {
            let cssClass = `checkbox-container`;

            if (this.isPulsating) {
                cssClass += ' pulsate';
            }

            if (this.color === 'blue') {
                return (cssClass += ' blue');
            }

            if (this.color === 'black') {
                return (cssClass += ' black');
            }

            return cssClass;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/base-url.scss';
@-webkit-keyframes shadow {
    0% {
        box-shadow: -1px -1px 5px rgba(218, 218, 218, 0.75);
        transform: scale(1);
    }
    50% {
        box-shadow: -5px -5px 13px rgba(218, 218, 218, 0.75);
        transform: scale(1.1);
    }
    100% {
        box-shadow: -1px -1px 5px rgba(218, 218, 218, 0.75);
        transform: scale(1);
    }
}
@-moz-keyframes shadow {
    0% {
        box-shadow: -1px -1px 5px rgba(218, 218, 218, 0.75);
    }
    50% {
        box-shadow: -1px -1px 13px rgba(218, 218, 218, 0.75);
    }
    100% {
        box-shadow: -1px -1px 5px rgba(218, 218, 218, 0.75);
    }
}
@keyframes shadow {
    0% {
        box-shadow: -1px -1px 5px rgba(218, 218, 218, 0.75);
        transform: scale(1);
    }
    50% {
        box-shadow: -1px -1px 13px rgba(218, 218, 218, 0.75);
        transform: scale(1.1);
    }
    100% {
        box-shadow: -1px -1px 5px rgba(218, 218, 218, 0.75);
        transform: scale(1);
    }
}

.custom-checkbox-component {
    display: flex;
    background: transparent;
    border: 0px;
    box-shadow: none;
    padding: 0px;
    align-items: center;
    text-align: left;
    &.pulsate {
        -webkit-animation: shadow 2s ease-in-out infinite;
        -moz-animation: shadow 2s ease-in-out infinite;
        animation: shadow 2s ease-in-out infinite;
    }
    .checkbox-label {
        &:empty {
            display: none;
        }
        margin-left: 10px;
        color: #000000;
    }
    .checkbox-container {
        border: solid #d1dae1 1px;
        width: 24px;
        height: 24px;
        background-color: #ffffff;
        flex-shrink: 0;
        align-self: flex-start;
        margin-top: 0px;
        &.pulsate {
            border: none;
        }
        &.active {
            background-image: url($baseAssetUrl+'images/check-blue-thick.svg');
            background-size: 16px 16px;
            background-repeat: no-repeat;
            background-position: center;
        }
        &.blue {
            border-radius: 3px;
            &.active {
                background-color: $color-blue;
                background-image: url($baseAssetUrl+'images/check-white-thick.svg');
            }
        }
        &.black {
            border-radius: 3px;
            &.active {
                background-image: url($baseAssetUrl+'images/check-black-thick.svg');
            }
        }
    }
    &:hover {
        .checkbox-container {
            box-shadow: 0px 0px 4px 0px $box-shadow-blue;
        }
        text-decoration: underline;
    }
}
</style>
