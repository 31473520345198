<template>
    <div class="footer--version2">
        <div class="footer-section--version2 bg-white">
            <div class="container--version2">
                <CustomTrustpilotWidget />
                <div class="payment-methods-container">
                    <img :src="formatAssetUrl('images/footer/visa.webp')" alt="visa" />
                    <img :src="formatAssetUrl('images/footer/mcard.webp')" alt="mastercard" />
                    <img :src="formatAssetUrl('images/footer/amex.webp')" alt="amex" />
                    <img :src="formatAssetUrl('images/footer/disc.webp')" alt="discover" />
                    <img :src="formatAssetUrl('images/footer/pp.webp')" alt="paypal" />
                    <img :src="formatAssetUrl('images/footer/amazon.webp')" alt="amazon pay" class="amazon-pay" />
                    <img :src="formatAssetUrl('images/footer/zip.webp')" alt="zip" class="zip" />
                    <img :src="formatAssetUrl('images/footer/applepay.webp')" alt="apple pay" />
                </div>
            </div>
        </div>
        <div class="footer-section--version2 bg-dark-blue">
            <div class="container--version2">
                <div class="communication-container">
                    <h6>
                        Stay tuned!
                    </h6>
                    <div>
                        Exclusive promotions, special offers and more
                    </div>
                    <form class="marketing-email-form-container" @submit.prevent="onSubscribe">
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Your email address"
                            id="email"
                            v-model="subscribeEmail"
                            autocomplete="off"
                        >
                        <CustomButton
                            type="submit"
                            :isLoading="isLoadingSubscribe"
                        >
                            Subscribe
                        </CustomButton>
                    </form>
                    <ContactOptions class="hidden-mobile" />
                </div>
                <div class="footer-links-container">
                    <FooterSection>
                        <template #header>Shop with us</template>
                        <li><nuxt-link to="/contact-lenses">Contact Lenses</nuxt-link></li>
                        <li><nuxt-link to="/glasses">Glasses</nuxt-link></li>
                        <li><nuxt-link to="/sunglasses">Sunglasses</nuxt-link></li>
                        <li><a href="/lens-replacement">Lens Replacement</a></li>
                        <li><nuxt-link to="/accessories">Accessories</nuxt-link></li>
                        <li><nuxt-link to="/vision-test">Online Vision Test</nuxt-link></li>
                        <li><nuxt-link to="/all-brands">All Brands</nuxt-link></li>
                        <li><nuxt-link to="/bludefend">BluDefend Blue Light Blocking</nuxt-link></li>
                        <li><a href="https://islandoptics.com/" target="_blank" rel="noopener">Island Optics</a></li>
                        <li><nuxt-link to="/rebate">Rebates</nuxt-link></li>
                        <li><nuxt-link to="/app">LensDirect App</nuxt-link></li>
                    </FooterSection>
                </div>
                <div class="footer-links-container">
                    <FooterSection>
                        <template #header>We're here to help</template>
                        <li><nuxt-link to="/help">Contact Information</nuxt-link></li>
                        <li><a href="https://www.lensdirect.com/blog ">Blog</a></li>
                        <li><nuxt-link to="/profile/account-snapshot">My Account</nuxt-link></li>
                        <li><a href="/faq">Frequently Asked Questions</a></li>
                        <li><nuxt-link to="/face-shape-guide">Face Shape Guide</nuxt-link></li>
                        <li><nuxt-link to="/subscribe-and-save">Subscribe and save</nuxt-link></li>
                        <li><nuxt-link to="/pd-measurement">Measure Your PD</nuxt-link></li>
                        <li><nuxt-link to="/how-to-install-sunglass-lenses">How to Install Sunglass Lenses</nuxt-link></li>
                        <li><nuxt-link to="/how-to-identify-sunglasses">How to Identify your Sunglasses</nuxt-link></li>
                        <li><nuxt-link to="/how-lens-replacement-works">How Lens Replacement Works</nuxt-link></li>
                        <li><a href="#" @click.prevent="onClickEnableAccessibilityOptions">Enable Accessibility Options</a></li>
                    </FooterSection>
                </div>
                <div class="footer-links-container">
                    <FooterSection>
                        <template #header>Information</template>
                        <li><nuxt-link to="/the-company">The Lens Direct Story</nuxt-link></li>
                        <li>
                            <a
                                href="https://signup.cj.com/member/signup/publisher/?cid=3117602#/branded"
                                target="_blank"
                                rel="noopener"
                                >Affiliate program</a
                            >
                        </li>
                        <li><nuxt-link to="/rewards">LD Rewards</nuxt-link></li>
                        <!-- <li><nuxt-link to="/refer">Refer a friend</nuxt-link></li> -->
                        <li><nuxt-link to="/reviews">Reviews</nuxt-link></li>
                        <li><nuxt-link to="/return-policy">Return policy</nuxt-link></li>
                        <li><nuxt-link to="/warranty">Warranty</nuxt-link></li>
                        <li><nuxt-link to="/heroes-offer">Heroes Offer</nuxt-link></li>
                        <li><nuxt-link to="/california-consumer-privacy-act">CCPA</nuxt-link></li>
                        <li><nuxt-link to="/guest/check-order-status">Check Order Status</nuxt-link></li>
                        <li><nuxt-link to="/guest/check-autorefill-status">Check Subscription Status</nuxt-link></li>
                    </FooterSection>
                </div>
                <ContactOptions class="hidden-desktop" />
            </div>
            <div class="container--version2 lower-footer">
                <div class="footer-terms-container">
                    <nuxt-link to="/privacy-policy">Privacy Policy</nuxt-link>
                    <span>|</span>
                    <nuxt-link to="/terms-and-conditions">Terms & Conditions</nuxt-link>
                </div>
                <div class="footer-logo-container">
                    <img src="~assets/images/logo-white.svg" />
                </div>
                <div class="footer-social-media-container">
                    <a href="https://www.facebook.com/shoplensdirect" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/facebook.webp')"
                            aria-label="Facebook"
                            alt="Facebook"
                        />
                    </a>
                    <a href="https://instagram.com/lensdirect" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/instagram.webp')"
                            aria-label="Instagram"
                            alt="Instagram"
                        />
                    </a>
                    <a href="https://twitter.com/lensdirect" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/twitter.webp')"
                            aria-label="Twitter"
                            alt="Twitter"
                        />
                    </a>
                    <a href="https://www.pinterest.com/lensdirect/" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/pinterest.webp')"
                            aria-label="Pinterest"
                            alt="Pinterest"
                        />
                    </a>
                    <a href="https://www.tiktok.com/@lensdirect" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/tiktok.webp')"
                            aria-label="tiktok"
                            alt="tiktok"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import TrustpilotMicroTrustScore from '@/components/third-party/TrustpilotMicroTrustScore';
import CustomTrustpilotWidget from '@/components/landing-page/CustomTrustpilotWidget';
import FooterSection from '@/components/FooterSection';
import ContactOptions from '@/components/ContactOptions';
import formatImageMixin from '@/mixins/image-url-formatter';
import accessibilityOptionsMixin from '@/mixins/accessibility-options-mixin';

export default {
    mixins: [formatImageMixin, accessibilityOptionsMixin],
    components: {
        CustomTrustpilotWidget,
        FooterSection,
        ContactOptions
    },
    data() {
        return {
            subscribeEmail: '',
            isLoadingSubscribe: false
        }
    },
    methods: {
        async onSubscribe() {
            if (this.subscribeEmail === '' || !this.validateEmail(this.subscribeEmail)) {
                this.$toast.error('Email address is required.', {
                    icon: 'fas fa-times'
                });
                return;
            }

            this.isLoadingSubscribe = true;
            let payload = {
                email: this.subscribeEmail
            };

            try {
                await this.$axios.$post('/subscribe-email', payload);

                this.$toast.success(`Subscribed successfully!`, {
                    icon: 'fas fa-user-check'
                });
                this.subscribeEmail = ''
            } catch (e) {
                this.$toast.error('We were unable to subscribe you at this time.  Please try again later.', {
                    icon: 'fas fa-times'
                });
            }

            this.isLoadingSubscribe = false;
        },
        validateEmail(email) {
            return /^\S+@\S+\.\S+$/.test(email);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.footer--version2 {
    font-size: 16px;
    .footer-section--version2 {
        padding: 20px;
        &.bg-white {
            border-top: solid #f4f7f9 1px;
            .container--version2 {
                display: flex;
                justify-content: space-between;
                .payment-methods-container {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    .amazon-pay {
                        margin-top: 5px;
                    }
                    .zip {
                        margin-bottom: 5px;
                    }
                }
            }
        }
        &.bg-dark-blue {
            color: #ffffff;
            padding-top: 60px;
            padding-bottom: 30px;
            .container--version2 {
                display: flex;
                gap: 6.67%;
                &.lower-footer {
                    font-size: .8125em;
                    gap: 0px;
                    justify-content: space-between;
                    margin-top: 40px;
                    padding-top: 30px;
                    border-top: solid rgba(112,112,112,.24) 1px;
                    .footer-terms-container {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        a {
                            color: #ffffff;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    .footer-logo-container {
                        img {
                            height: 30px;
                        }
                    }
                    .footer-social-media-container {
                        display: flex;
                        gap: 12px;
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba(255,255,255,.06);
                            border-radius: 100px;
                            width: 50px;
                            height: 50px;
                            transition: background-color .1s;
                            &:hover {
                                background-color: rgba(255,255,255,.12);
                            }
                        }
                    }
                }
            }
            ::v-deep {
                h6 {
                    color: #ffffff;
                    font-size: 1.625em;
                    line-height: 1.2em;
                    font-weight: bold;
                }
                .single-footer-widget {
                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        list-style: none;
                        margin: 0px;
                        padding: 0px;
                        padding-top: 30px;
                        li {
                            padding: 0px;
                            margin: 0px;
                            a {
                                color: #ffffff;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
            .communication-container {
                width: 30.07%;
                .marketing-email-form-container {
                    display: flex;
                    padding-top: 20px;
                    input {
                        font-size: 1em;
                        border-radius: 0px;
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }
                    button {
                        color: #ffffff;
                        border-radius: 0px;
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                        background-color: #11b681;
                        border: 0px;
                        font-size: 1.125em;
                        font-weight: bold;
                        padding: 10px 20px;
                        &:hover {
                            background-color: #0e996c;
                        }
                        &:active,&:focus {
                            background-color: #0a8f64;
                        }
                    }
                }
            }
            .footer-links-container {
                width: 16.64%;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .footer--version2 {
        .footer-section--version2 {
            &.bg-white {
                .container--version2 {
                    justify-content: center;
                    .payment-methods-container {
                        display: none;
                    }
                }
            }
            &.bg-dark-blue {
                padding-top: 30px;
                ::v-deep {
                    .single-footer-widget {
                        h6 {
                            font-size: 1em;
                        }
                    }
                }
                .container--version2 {
                    flex-direction: column;
                    &.lower-footer {
                        gap: 15px;
                        flex-direction: column-reverse;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .communication-container {
                    width: 100%;
                    .marketing-email-form-container {
                        padding-bottom: 30px;
                        border-bottom: solid rgba(112,112,112,.24) 1px;
                        margin-bottom: 20px;
                    }
                }
                .footer-links-container {
                    width: 100%;
                }
            }
        }
    }
}
</style>
