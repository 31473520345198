<template>
    <div class="mobile-summary-component">
        <div class="price-container" v-if="excludeCoupons">
            Price: <strong class="text-red">{{ formatMoney(priceForDisplay) }}</strong>
        </div>
        <div class="price-container" v-else>
            Price with coupon: <strong class="text-red">${{ formatMoney(priceForDisplay) }}</strong> <span class="original-price">${{ formatMoney(estimatedPrice) }}</span>
        </div>
        <client-only>
            <div class="zip-container" v-if="priceForDisplay && !isNaN(priceForDisplay)">
                or 4 interest-free installments of <strong>$<ZipAmount :amount="priceForDisplay" :useMoneyFormatter="true" /></strong> with <img :src="formatAssetUrl('images/zip-logo.svg')" alt="zip logo - buy now pay later" class="zip-logo">
            </div>
        </client-only>
    </div>
</template>

<script>
import ZipAmount from '@/components/cart/payment-methods/ZipAmount';

import formatMoneyMixin from '@/mixins/money-formatter';
import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    components: {
        ZipAmount
    },
    mixins: [formatMoneyMixin, formatImageUrlMixin],
    data() {
        return {
            couponDiscount: .2
        }
    },
    computed: {
        baseProduct() {
            return this.$store.state.eyewearFlow.baseProduct;
        },
        priceForDisplay() {
            return this.excludeCoupons ? this.estimatedPrice : this.estimatedPriceWithDiscount;
        },
        estimatedPriceWithDiscount() {
            return this.estimatedPrice * (1 - this.couponDiscount);
        },
        estimatedPrice() {
            return this.$store.getters['eyewearFlowV2/estimatedPrice'];
        },
        excludeCoupons() {
            if (this.baseProduct?.excludeCoupons) {
                return true;
            }

            return this.baseProduct?.frameProduct?.excludeCoupons ? true : false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.mobile-summary-component {
    display: none;
}

@media (max-width: $breakpoint2) {
    .mobile-summary-component {
        display: block;
        text-align: center;
        font-size: .875em;
        .price-container {
            .text-red {
                color: #CB1F2B !important;
            }
            .original-price {
                text-decoration: line-through;
            }
            strong {
                font-size: 1.42857em;
            }
        }
        .zip-container {
            text-align: center;
            font-size: .85714em;
            color: #515E68;
            .zip-logo {
                display: inline-block;
                vertical-align: top;
                width: 40px;
                margin-top: 0px;
                margin-left: 3px;
            }
        }
    }
}
</style>
