<template>
    <AppWrapper v-if="user">
        <Header></Header>
        <Navbar></Navbar>
        <div class="admin-container">
            <AppSidebar />
            <nuxt />
        </div>
    </AppWrapper>
</template>

<script>
import Header from '@/layouts/Header';
import Navbar from '@/layouts/Navbar';
import AppSidebar from '@/layouts/Sidebar';
import AppWrapper from '@/components/AppWrapper';

export default {
    components: {
        AppWrapper,
        Header,
        Navbar,
        AppSidebar
    },
    async beforeMount() {
        await this.$store.dispatch('auth/getUser', true);

        if (!this.$store.state.auth.currentUser?.customer) {
            this.$store.dispatch('auth/setLoginRedirect', this.$route?.fullPath);
            this.$router.push({ path: '/user/login' });
            return;
        }
    },
    computed: {
        user() {
            return this.$store.state.auth.currentUser?.customer;
        }
    }
}
</script>
