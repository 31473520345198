<template>
    <div class="single-footer-widget">
        <div class="footer-title">
            <h6 @click="expand = !expand"><slot name="header"></slot></h6>
            <div class="expand-wrapper hidden-desktop">
                <button type="button" class="btn-expand" @click="expand = !expand">
                    <img :src="buttonImage" />
                </button>
            </div>
        </div>
        <TransitionExpand>
            <div v-show="expand" class="quick-links-wrapper">
                <ul class="quick-links">
                    <slot></slot>
                </ul>
            </div>
        </TransitionExpand>
    </div>
</template>

<script>
import TransitionExpand from '@/components/TransitionExpand';
import imageUrlFormatterMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [imageUrlFormatterMixin],
    components: {
        TransitionExpand
    },
    props: {
        defaultExpand: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expand: this.defaultExpand
        };
    },
    computed: {
        buttonImage() {
            return this.formatAssetUrl(this.expand ? 'images/minus-white.svg' : 'images/plus-white.svg');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/font.scss';

.btn-expand {
    border: 0px;
    background-color: transparent;
    img {
        max-width: 14px;
    }
}

.footer-title {
    h6 {
        font-family: $font-primary;
        pointer-events: none;
    }
}
@media (min-width: $minBreakpoint3) {
    .single-footer-widget .quick-links-wrapper {
        display: block !important;
    }
}

@media (max-width: $breakpoint3) {
    .single-footer-widget {
        border-bottom: solid rgba(112,112,112,.24) 1px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .footer-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h6 {
            font-family: $font-primary;
            pointer-events: auto;
        }
    }

    .single-footer-widget h6 {
        margin-bottom: 0px;
    }

    .single-footer-widget .quick-links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 15px;
        li {
            width: 50%;
        }
    }
}
</style>
