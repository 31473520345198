<template>
    <div class="mobile-nav-wrapper">
        <HamburgerButton @click="onClickMobileMenuButton" :toggled="isVisible" />

        <transition name="slide-right">
            <div class="mobile-nav-content-container" v-show="isVisible" :class="{ 'search-open': isSearchVisible }">
                <MobileSearchPanel v-if="isSearchVisible" />
                <div class="mobile-nav-content-scroll">
                    <div class="search-button-container">
                        <button class="btn-search" @click="onClickSearch">
                            <img :src="formatAssetUrl('images/search.png')" alt="search icon" />
                            I'm searching for...
                        </button>
                    </div>
                    <div class="menu-categories-container">
                        <MobileNavCategory @clickedLink="onClickLink" :links="contactLensLinks">
                            <img :src="formatAssetUrl('images/menu/mobile-contacts.png')" class="product-icon" alt="contact lenses box" /> Contact Lenses
                        </MobileNavCategory>
                        <MobileNavCategory :links="glassesLinks" @clickedLink="onClickLink">
                            <img
                                :src="formatAssetUrl('images/menu/mobile-glasses.png')"
                                class="product-icon hidden-desktop"
                                alt="glasses"
                            />
                            Glasses
                        </MobileNavCategory>
                        <MobileNavCategory :links="sunglassesLinks" @clickedLink="onClickLink">
                            <img :src="formatAssetUrl('images/menu/mobile-sunglasses.png')" class="product-icon" alt="sunglasses" />
                            Sunglasses
                        </MobileNavCategory>
                        <MobileNavCategory :links="lensReplacementLinks" @clickedLink="onClickLink">
                            <img :src="formatAssetUrl('images/menu/mobile-lens-replacement.png')" class="product-icon" alt="glasses with lenses popped out" />
                            Lens Replacement
                        </MobileNavCategory>
                        <MobileNavCategory :links="accessoriesLinks" @clickedLink="onClickLink">
                            <img :src="formatAssetUrl('images/menu/mobile-accessories.png')" class="product-icon" alt="contact lens case" /> More
                        </MobileNavCategory>
                    </div>
                    <div class="mobile-nav-secondary-links">
                        <div>
                            <a href="/rewards" @click="onClickLink">
                                LD Rewards
                            </a>
                        </div>
                        <div>
                            <a href="/faq" @click="onClickLink">
                                FAQ's
                            </a>
                        </div>
                        <div>
                            <nuxt-link to="/guest/check-order-status" @click="onClickLink">
                                Check Status on Order
                            </nuxt-link>
                        </div>
                        <div>
                            <a href="/face-shape-guide" @click="onClickLink">
                                Face Shape Guide
                            </a>
                        </div>
                        <div>
                            <a href="/insurance" @click="onClickLink">
                                Vision Insurance
                            </a>
                        </div>
                        <div>
                            <a href="/how-lens-replacement-works" @click="onClickLink">
                                How Lens Replacement Works
                            </a>
                        </div>
                        <div>
                            <a href="/how-to-install-sunglass-lenses" @click="onClickLink">
                                How to Install Sunglass Lenses
                            </a>
                        </div>
                        <div>
                            <a href="/how-to-identify-sunglasses" @click="onClickLink">
                                How to Identify Your Sunglasses
                            </a>
                        </div>
                        <div>
                            <a href="/face-shape-guide" @click="onClickLink">
                                How to Dot the Pupils
                            </a>
                        </div>
                        <div class="social-icons-wrapper">
                            <a href="https://www.facebook.com/shoplensdirect" target="_blank" rel="noopener">
                                <font-awesome-icon icon="fa-brands fa-facebook" aria-label="Facebook" alt="Facebook" />
                            </a>
                            <a href="https://instagram.com/lensdirect" target="_blank" rel="noopener">
                                <font-awesome-icon icon="fa-brands fa-instagram" aria-label="Instagram" alt="Instagram" />
                            </a>
                            <a href="https://twitter.com/lensdirect" target="_blank" rel="noopener">
                                <font-awesome-icon icon="fa-brands fa-twitter" aria-label="Twitter" alt="Twitter" />
                            </a>
                            <a href="https://www.pinterest.com/lensdirect/" target="_blank" rel="noopener">
                                <font-awesome-icon icon="fa-brands fa-pinterest" aria-label="Pinterest" alt="Pinterest" />
                            </a>
                            <a href="https://www.tiktok.com/@lensdirect" target="_blank" rel="noopener">
                                <font-awesome-icon icon="fa-brands fa-tiktok" aria-label="TikTok" alt="TikTok" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="mobile-nav-footer">
                    <a class="btn btn-primary" :href="userLink">{{ userLinkText }}</a>
                    <a class="btn btn-outline" href="/help">Help</a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import freezeMobileBackdrop from '@/utils/freeze-mobile-backdrop';
import MobileNavCategory from '@/components/MobileNavCategory';
import MobileSearchPanel from '@/components/MobileSearchPanel';
import imageUrlFormatterMixin from '@/mixins/image-url-formatter';
import HamburgerButton from '@/components/HamburgerButton';

export default {
    mixins: [imageUrlFormatterMixin],
    components: {
        MobileNavCategory,
        MobileSearchPanel,
        HamburgerButton
    },
    computed: {
        isSearchVisible() {
            return this.$store.state.search.isVisible;
        },
        isVisible() {
            return this.$store.state.mobileMenuOpen;
        },
        isCustomer() {
            let currentUser = this.$store.getters['auth/currentUser'];
            return currentUser?.customer ? true : false;
        },
        userLinkText() {
            return this.isCustomer ? 'My Account' : 'Sign In';
        },
        userLink() {
            return this.isCustomer ? '/profile/account-snapshot' : '/user/login';
        },
        buttonIcon() {
            return this.formatAssetUrl(this.isVisible ? 'images/menu-close.svg' : 'images/menu.svg');
        },
        contactLensLinks() {
            return [
                {
                    url: '/acuvue-contacts',
                    text: 'Acuvue'
                },
                {
                    url: '/air-optix',
                    text: 'Air Optix'
                },
                {
                    url: '/dailies',
                    text: 'Dailies'
                },
                {
                    url: '/biofinity',
                    text: 'Biofinity'
                },
                {
                    url: '/bausch-and-lomb',
                    text: 'Bausch and Lomb'
                },
                {
                    url: '/contact-lenses',
                    text: 'Shop all contacts'
                },
            ];
        },
        glassesLinks() {
            return [
                {
                    url: '/womens-designer-eyeglasses',
                    text: "Women's Glasses"
                },
                {
                    url: '/mens-prescription-glasses',
                    text: 'Men\'s Glasses'
                },
                {
                    url: '/glasses-best-sellers',
                    text: 'Best Sellers'
                },
                {
                    url: '/glasses-new-arrivals',
                    text: 'New Arrivals'
                },
                {
                    url: '/blue-light-glasses',
                    text: 'Blue Light Glasses'
                },
                {
                    url: '/glasses-under-35',
                    text: 'Glasses Under $35'
                },
                {
                    url: '/childrens-eyewear',
                    text: 'Children\'s Eyewear'
                },
                {
                    url: '/glasses',
                    text: 'Shop all glasses'
                },
            ];
        },
        sunglassesLinks() {
            return [
                {
                    url: '/womens-sunglasses',
                    text: "Women's Sunglasses"
                },
                {
                    url: '/mens-sunglasses',
                    text: "Men's Sunglasses"
                },
                {
                    url: '/sunglasses-best-sellers',
                    text: 'Best Sellers'
                },
                {
                    url: '/sunglasses-new-arrivals',
                    text: 'New Arrivals'
                },
                {
                    url: '/hyannis-sunglasses',
                    text: 'Hyannis Sunglasses'
                },
                {
                    url: '/holly-sunglasses',
                    text: 'Holly Sunglasses'
                },
                {
                    url: '/brian-mazza-collection',
                    text: 'Brian Mazza Collection'
                },
                {
                    url: '/sunglasses',
                    text: 'Shop all sunglasses'
                },
            ];
        },
        lensReplacementLinks() {
            return [
                {
                    url: '/lens-replacement/prescription-lens-replacement',
                    text: 'Prescription Eyeglasses'
                },
                {
                    url: '/lens-replacement/prescription-sunglasses-lens-replacement',
                    text: 'Prescription Sunglasses'
                },
                {
                    url: '/lens-replacement/non-prescription-sunglass-lens-replacement',
                    text: 'Non-Prescription Sunglasses'
                },
                {
                    url: '/diy-lens-replacement/choose-your-brand',
                    text: 'DIY Lens Replacement'
                }
            ];
        },
        accessoriesLinks() {
            return [
                {
                    url: '/accessories',
                    text: 'Accessories'
                },
                {
                    url: '/vision-test',
                    text: 'Vision Test'
                },
                {
                    url: '/giftcards',
                    text: 'Gift Cards'
                },
                {
                    url: '/rewards',
                    text: 'Rewards Program'
                }
            ];
        }
    },
    methods: {
        handleMenuOpenStatus(newStatus) {
            this.$store.dispatch('setMobileMenuOpen', newStatus);
            this.$store.dispatch('search/setIsVisibleStatus', false);

            freezeMobileBackdrop(newStatus);
        },
        onClickMobileMenuButton() {
            this.handleMenuOpenStatus(!this.isVisible);
        },
        onClickSearch() {
            this.$store.dispatch('search/setIsVisibleStatus', true);
            this.$store.dispatch('search/openedFromMenu');
        },
        onClickLink() {
            this.handleMenuOpenStatus(false);
        }
    },
    watch: {
        $route() {
            if (!this.isVisible) {
                return;
            }

            this.onClickLink();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.mobile-nav-wrapper {
    display: none;
}
@media (max-width: $breakpoint2) {
    .mobile-nav-wrapper {
        position: absolute;
        display: block;
        top: 5px;
        left: 10px;
    }

    .btn-mobile-menu {
        border: 0px;
        background-color: transparent;
    }

    .mobile-nav-content-container {
        position: fixed;
        z-index: 10000;
        top: 55px;
        background-color: $bg-light-grey;
        left: 0px;
        right: 0px;
        bottom: auto;
        transition: left .2s, right .2s;
        &.search-open {
            top: 0px;
            z-index: 1300;
            &::v-deep {
                .mobile-search-panel {
                    top: 0px !important;
                }
            }
        }
        &.slide-right-enter-from {
            left: -100%;
            right: auto;
            &::v-deep {
                .mobile-search-panel {
                    left: -100%;
                    right: 100%;
                }
            }
        }
        &.slide-right-enter-active {
            left: -100%;
            right: 100%;
            &::v-deep {
                .mobile-search-panel {
                    left: -100%;
                    right: 100%;
                }
            }
        }
        &.slide-right-enter-to {
            left: 0px;
            right: 0px;
            &::v-deep {
                .mobile-search-panel {
                    left: 0px;
                    right: 0px;
                }
            }
        }

        &.slide-right-leave-from {
            left: 0px;
            right: 0px;
            &::v-deep {
                .mobile-search-panel {
                    left: 0px;
                    right: 0px;
                }
            }
        }
        &.slide-right-leave-active {
            left: -100%;
            right: 100%;
            &::v-deep {
                .mobile-search-panel {
                    left: -100%;
                    right: 100%;
                }
            }
        }
        &.slide-right-leave-to {
            left: -100%;
            right: auto;
            &::v-deep {
                .mobile-search-panel {
                    left: -100%;
                    right: 100%;
                }
            }
        }

        .search-button-container {
            position: relative;
            background-color: #ffffff;
            padding: 15px;
            border-bottom: solid $color-grey-border 1px;
            .btn-search {
                background-color: $bg-light-grey;
                border: solid #e5eaee 1px;
                border-radius: 6px;
                padding: 12px;
                width: 100%;
                text-align: left;
                color: $color-grey-input;
                font-size: 16px;
                img {
                    margin-right: 8px;
                }
            }
        }
        .menu-categories-container {
            position: relative;
        }
    }

    .hidden-top-panel {
        .mobile-nav-content-container {
            top: 55px;
        }
    }

    .product-icon {
        max-width: 100px;
        margin-right: 15px;
    }

    .mobile-nav-content-scroll {
        height: calc(100vh - 130px);
        overflow: auto;
        overscroll-behavior: contain;
        padding-bottom: 100px;
    }

    .search-open {
        .mobile-nav-content-scroll {
            display: none;
        }
    }

    .mobile-nav-secondary-links {
        text-align: left;
        padding: 30px 20px;
        a {
            display: inline-block;
            color: $color-grey-text;
            padding-bottom: 10px;
        }
        .social-icons-wrapper {
            padding-top: 15px;
            a {
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                color: #000000;
                padding: 10px;
                border: solid $color-grey-border 1px;
                border-radius: 10px;
                margin-right: 10px;
                margin-bottom: 10px;
                font-size: 24px;
                width: 50px;
                height: 50px;
            }
        }
    }
    .mobile-nav-footer {
        position: fixed;
        width: 100%;
        bottom: 0px;
        height: 90px;
        display: flex;
        justify-content: space-between;
        padding: 15px;
        background-color: $bg-light-grey;
        box-shadow: 0px -5px 10px #0000000d;
        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48%;
        }
    }
    .search-open {
        .mobile-nav-footer {
            display: none;
        }
    }
}
</style>
