<template>
    <div class="eyewear-flow-side-bar-section" v-if="!isLoading">
        <div v-for="(stepDetail, index) in stepDetails" :key="index" class="eyewear-flow-summary-line">
            <label>{{ stepDetail.name }}</label>
            <div class="eyewear-flow-summary-value">
                <template v-if="stepDetail.value">
                    <strong>{{ stepDetail.value }}</strong>
                </template>
                <template v-else>
                    -
                </template>
            </div>
        </div>
        <div class="eyewear-flow-summary-line">
            <label>Shipping</label>
            <div class="eyewear-flow-summary-value text-green">
                <strong>Free</strong>
            </div>
        </div>
        <div class="eyewear-flow-summary-line total">
            <label><span class="hidden-mobile">Estimated&nbsp;</span>Total:</label>
            <div>${{ formatMoney(estimatedPrice, 0) }}</div>
        </div>
        <client-only>
            <div class="zip-container" v-if="estimatedPrice && !isNaN(estimatedPrice)">
                or 4 interest-free installments of <strong>$<ZipAmount :amount="estimatedPrice" :useMoneyFormatter="true" /></strong> with <img :src="formatAssetUrl('images/zip-logo.svg')" alt="zip logo - buy now pay later" class="zip-logo">
            </div>
        </client-only>

    </div>
    <div class="eyewear-flow-side-bar-section summary-loading" v-else>
        <div v-for="n in 6" :key="n" class="eyewear-flow-summary-line">
            <label><b-skeleton /></label>
            <div class="eyewear-flow-summary-value">
                <b-skeleton />
            </div>
        </div>
        <div class="eyewear-flow-summary-line total">
            <label>Total:</label>
            <div class="eyewear-flow-summary-value">
                <b-skeleton />
            </div>
        </div>
        <div class="eyewear-flow-summary-line zip-loading">
            <b-skeleton />
        </div>
    </div>
</template>

<script>
import ZipWidget from '@/components/cart/payment-methods/ZipWidget';
import ZipAmount from '@/components/cart/payment-methods/ZipAmount';
import formatMoneyMixin from '@/mixins/money-formatter';
import formatImageUrlMixin from '@/mixins/image-url-formatter';

import eyewearFlowMixin from '@/mixins/eyewear-flow';

import eyewearFlowSummaryMixin from '@/mixins/eyewear-flow-summary-mixin';

export default {
    mixins: [formatMoneyMixin, formatImageUrlMixin, eyewearFlowMixin, eyewearFlowSummaryMixin],
    components: {
        ZipWidget,
        ZipAmount
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        estimatedPrice() {
            return (
                this.$store.state.eyewearFlow?.estimatedProduct?.price + this.$store.state.eyewearFlow?.additionalPrice
            );
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.eyewear-flow-side-bar-section {
    .eyewear-flow-summary-line {
        label {
            .b-skeleton {
                width: 150px;
                height: 10px;
            }
        }
        .eyewear-flow-summary-value {
            .b-skeleton {
                width: 60px;
                height: 10px;
            }
        }
    }
    &.summary-loading {
        .eyewear-flow-summary-line {
            &.total {
                .eyewear-flow-summary-value {
                    display: flex;
                    align-items: center;
                }
            }
            &.zip-loading {
                display: flex;
                justify-content: center;
                margin-top: 10px;
                .b-skeleton {
                    width: 150px;
                    height: 10px;
                }
            }
        }
    }
    .zip-container {
        font-size: 14px;
        .zip-logo {
            display: inline-block;
            vertical-align: top;
            width: 40px;
            margin-top: 0px;
            margin-left: 3px;
        }
    }
}

@media (max-width: $breakpoint2) {
    .eyewear-flow-side-bar-section {
        .zip-container {
            font-size: 13px;
            .zip-logo {
                width: 34px;
                margin-top: 2px;
            }
        }
    }
}
</style>
