<template>
    <client-only>
        <div style="display: none"></div>
    </client-only>
</template>

<script>
const NAME = 'trackPerPage';

import { googleAdwordsRemarketingTracking } from '@/third-party/google';
import {
    googleAnalyticsUniversalTracking,
    googleAnalyticsV4Tracking
} from '@/third-party/google';
import facebookTracking from '@/third-party/facebook';
import { bingTracking } from '@/third-party/bing';
import yahooTracking from '@/third-party/yahoo';
import { cjCookieTracking } from '@/third-party/cj';
import { pinterestPageVisit } from '@/third-party/pinterest';
import linkedInTracking from '@/third-party/linkedin';
import { loadQuoraTracking, quoraTrackPageView } from '@/third-party/quora';
import loadSkimlinksTracking from '@/third-party/skimlinks';
import { loadAttentiveTracking } from '@/third-party/attentive';
import accessibe from '@/third-party/accessibe';
import { loadSafeOptTracking } from '@/third-party/safe-opt';
import loadDataDog from '@/third-party/datadog';

export default {
    mounted() {
        if (this.isLoaded) {
            return;
        }

        if (this.$config.isDev) {
            return;
        }

        this.trackSingleTime();
        this.trackEveryPageView();

        this.$store.dispatch('thirdParty/setLoadedScript', NAME);
    },
    computed: {
        isLoaded() {
            return this.$store.getters['thirdParty/isLoaded'](NAME);
        },
        customerEmail() {
            return this.$store.state?.currentUser?.customer?.email;
        }
    },
    methods: {
        trackGoogleAdwordsRemarketing() {
            if (this.$route?.meta?.productType) {
                return;
            }

            if (this.$route?.meta?.pageType === 'category') {
                return;
            }

            let skipRoutes = [
                '/cart',
                '/checkout'
            ];

            for (let x = 0; x < skipRoutes.length; x++) {
                if (this.$route.path.indexOf(skipRoutes[x]) !== -1) {
                    return;
                }
            }

            googleAdwordsRemarketingTracking('other');
        },
        trackSingleTime() {
            if (this.$config.isDev) {
                return;
            }

            googleAnalyticsV4Tracking();
            cjCookieTracking(this.$axios, this.$route);
            linkedInTracking();
            loadQuoraTracking();
            loadSkimlinksTracking();
            loadAttentiveTracking();
            accessibe();
            loadSafeOptTracking();
            loadDataDog(this.$config.datadog);
        },
        trackEveryPageView() {
            if (this.$config.isDev) {
                return;
            }

            googleAnalyticsUniversalTracking(this.$route);
            facebookTracking(this.$axios, this.$route.path);
            bingTracking();
            yahooTracking();
            pinterestPageVisit(this.$route);
            quoraTrackPageView();
        }
    },
    destroyed() {
        this.trackEveryPageView();
    },
    watch: {
        $route(newRoute) {
            this.trackEveryPageView();
        }
    }
}
</script>
