<template>
    <div class="app-wrapper">
        <Gladly />
        <slot></slot>
        <Facebook />
        <AmazonLogin />
        <script src="//vto-advanced-integration-api.fittingbox.com/index.js" type="text/javascript" />
        <Yotpo />
        <YotpoRewards />
        <Clarity />
        <TrackingCodes />
        <StyledModal
            v-if="showAccountDeactivatedMessage"
            v-model="showAccountDeactivatedMessage"
            @close="showAccountDeactivatedMessage = false"
        >
            <template #title>
                <div class="text-center">
                    <h6>Account Deactivated</h6>
                </div>
            </template>
            <p class="text-center inline-links-section">
                Your account has been deactivated.  Please <a href="/help">contact us</a> to activate your account.
            </p>
        </StyledModal>
        <div id="glasseson"></div>
    </div>
</template>

<script>
import Gladly from '@/components/third-party/Gladly';
import Facebook from '@/components/third-party/Facebook';
import AmazonLogin from '@/components/third-party/AmazonLogin';
import Yotpo from '@/components/third-party/Yotpo';
import TrackingCodes from '@/components/third-party/TrackingCodes';
import StyledModal from '@/components/modals/StyledModal';
import YotpoRewards from '@/components/third-party/YotpoRewards';
import Clarity from '@/components/third-party/Clarity';
import storage from '@/utils/storage';

export default {
    components: {
        Gladly,
        Facebook,
        AmazonLogin,
        Yotpo,
        TrackingCodes,
        StyledModal,
        YotpoRewards,
        Clarity
    },
    async fetch() {
        await this.$store.dispatch('uiComponents/loadUiComponents');

        let themeResult = await this.$store.dispatch('loadThemeSource');
        if (themeResult) {
            this.themeSessionToken = themeResult.token;
        }
    },
    async beforeMount() {
        let promises = [
            this.$store.dispatch('initCart', true),
            this.$store.dispatch('auth/getUser', true),
        ];

        if (!storage.get('themeSessionToken')) {
            promises.push(this.$store.dispatch('loadThemeSource', this.themeSessionToken));
        }

        await Promise.all(promises);

        await this.$store.dispatch('favorites/loadCustomerFavorites');

        this.checkForDeactivatedAccount();
    },
    mounted() {
        if (!this.hideChatMobile) {
            document.body.classList.remove('hide-chat-window-mobile');
        } else {
            document.body.classList.add('hide-chat-window-mobile');
        }

        document.body.addEventListener('reloadApp', this.onWebsiteUpdate);

        this.$store.dispatch('loadTrustpilotData');

        this.checkShowDeactivatedAccountMessage();
    },
    destroyed() {
        document.body.removeEventListener('reloadApp', this.onWebsiteUpdate);
    },
    props: {
        hideChatMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            themeSessionToken: '',
            showAccountDeactivatedMessage: false
        }
    },
    methods: {
        onWebsiteUpdate() {
            this.$toast.success('Our website has been updated!  The page will automatically refresh in the next 5 seconds so you can get the update.');
            window.setTimeout(() => {
                window.location.reload();
            }, 5000);
        },
        checkForDeactivatedAccount() {
            if (!this.$store.state.auth?.currentUser?.customer) {
                return;
            }

            if (this.$store.state.auth?.currentUser?.customer?.isActive) {
                return;
            }

            // Redirect customer to be logged out
            this.$store.dispatch('auth/setShowAccountDeactivatedMessage', true);
            this.$router.push('/profile/logout');
        },
        checkShowDeactivatedAccountMessage() {
            if (!this.$store.state.auth.showAccountDeactivatedMessage) {
                return;
            }

            this.$store.dispatch('auth/setShowAccountDeactivatedMessage', false);
            this.showAccountDeactivatedMessage = true;
        }
    }
}
</script>
