<template>
    <AppWrapper :hideChatMobile="true">
        <Header :hideAuth="true" />
        <DesktopHeader class="lens-replacement-header" v-if="flowTree && baseProduct" />
        <div class="mobile-header">
            <a href="/" @click.prevent="onClickLogo"><img :src="websiteLogo()" alt="lensdirect logo" :class="websiteLogoCss()" /></a>
        </div>
        <div class="main-content">
            <div v-if="!baseProduct || !flowTree">
                <StepsSkeleton />
            </div>
            <div style="width: 100%" v-if="baseProduct">
                <div class="eyewear-flow-container" v-if="flowTree">
                    <client-only>
                        <nuxt />
                    </client-only>

                    <Sidebar />
                </div>
            </div>
        </div>
        <PreloadImages />

        <ConfirmExitModal
            :show="showConfirmExit"
            @close="showConfirmExit = false"
            @confirm="onConfirmExit"
        />
    </AppWrapper>
</template>

<script>
import AppWrapper from '@/components/AppWrapper';
import Header from '@/layouts/Header';
import DesktopHeader from '@/components/landing-page/lens-replacement/steps-v2/DesktopHeader';
import Sidebar from '@/components/landing-page/lens-replacement/steps-v2/Sidebar';
import StepsSkeleton from '@/components/landing-page/lens-replacement/steps/StepsSkeleton';
import PreloadImages from '@/components/landing-page/lens-replacement/steps-v2/PreloadImages';
import ConfirmExitModal from '@/components/landing-page/choose-lenses/ConfirmExitModal';

import lensReplacementLoadProductMixin from '@/mixins/eyewear-flow-load-lens-replacement-product';
import templateSetupMixin from '@/mixins/eyewear-flow-v2/template-setup-mixin';
import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [templateSetupMixin, formatImageUrlMixin, ConfirmExitModal, lensReplacementLoadProductMixin],
    components: {
        AppWrapper,
        Header,
        DesktopHeader,
        Sidebar,
        StepsSkeleton,
        PreloadImages,
        ConfirmExitModal
    },
    async mounted() {
        if (!this.baseProduct) {
            await this.loadProduct();
        }

        await this.loadFlowData();
    },
    data() {
        return {
            showConfirmExit: false
        }
    },
    computed: {
        baseProduct() {
            return this.$store.state.eyewearFlow.baseProduct;
        },
        flowTree() {
            return this.$store.state.eyewearFlowV2.flowTree;
        }
    },
    methods: {
        onClickLogo() {
            this.showConfirmExit = true;
        },
        onConfirmExit() {
            window.location = '/';
        }
    }
}
</script>

<style lang="scss">
@import '~/assets/scss/components/landing-page/eyewear-flow.scss';
</style>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.app-wrapper {
    position: relative;
    min-height: 100vh;
    background-color: $bg-light-blue;
    .main-content {
        background-color: $bg-light-blue;
        margin-top: 100px;
        .progress-bar-stick-wrapper {
            display: none;
        }
    }
}

.mobile-header {
    display: none;
}

::v-deep {
    .trustpilot-bottom {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }

    .glasses-flow-header {
        &.lens-replacement-header {
            position: fixed;
            top: 40px;
            width: 100%;
            .steps-wrapper {
                .step-container {
                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }

    .eyewear-flow-container {
        padding-top: 20px;
    }
}

@media (max-width: $desktopBreakpoint1) {
    ::v-deep {
        .glasses-flow-header {
            &.lens-replacement-header {
                .steps-wrapper {
                    .step-container {
                        &::after {
                            width: 25px;
                        }
                    }
                }
                .live-chat-wrapper {
                    min-width: 200px;
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint2) {
    ::v-deep {
        .glasses-flow-header {
            &.lens-replacement-header {
                .container {
                    width: 100%;
                }
                .steps-wrapper {
                    .step-container {
                        &::after {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint4) {
    ::v-deep {
        .glasses-flow-header {
            &.lens-replacement-header {
                .steps-wrapper {
                    .step-name {
                        font-size: 10px;
                    }
                    .step-container {
                        &::after {
                            width: 15px;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: $breakpoint2) {
    .app-wrapper {
        .main-content {
            margin-top: 0px;
        }
    }

    .progress-bar-stick-wrapper {
        position: sticky;
        display: flex;
        top: 55px;
        z-index: 10;
    }

    .mobile-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 60px;
        width: 100%;
        background-color: #ffffff;
        z-index: 10;
        border-bottom: solid $color-grey-border 1px;
        img {
            height: 30px;
        }
    }

    .eyewear-flow-container {
        padding-top: 0px;
    }

    .show-mobile-next-step-button {
        ::v-deep {
            .eyewear-flow-container {
                .eyewear-flow-step-container {
                    .next-step-button-container {
                        &.is-sticky-mobile-buttons {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint5) {
    .app-wrapper {
        padding-bottom: 80px;
    }
}
</style>

