<template>
    <div class="product-preview-component" v-if="baseProduct">
        <div class="back-button-wrapper">
            <BackButton
                arrowImage="images/arrow-left-gray.svg"
                arrowImageHover="images/arrow-left-gray.svg"
                :overrideOnClick="onClickBackToFrame"
            >
                Back to frame
            </BackButton>
        </div>

        <div class="product-image-container">
            <img :src="formatProductImageUrl(productImage.url)" :alt="productImage.alt ? productImage.alt : 'frame preview image'" />
            <div class="lens-color-detail-container" v-if="showLensColorSwatch" :class="{ 'toggle-swatch': toggleSwatch, 'animate-swatch': animateSwatch }">
                <div class="lens-color-swatch" :style="lensColorCss"></div>
                <div>
                    <small>
                        Lens color:
                    </small>
                    <strong>{{ lensColor }}</strong>
                </div>
            </div>
        </div>
        <div class="frame-detail-container">
            <h3>{{ frameName }}</h3>
            <small>
                {{ frameNameDetail }}
            </small>
        </div>

        <div class="order-total-summary-container" v-if="estimatedPrice && !hasCoatingSelected">
            <div class="row" v-if="!excludeCoupons">
                <div class="col-8">
                    Best value coupon applied:
                </div>
                <div class="col-4 text-right">
                    {{ couponCode }}
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    Shipping & Handling:
                </div>
                <div class="col-4 text-right">
                    $0
                </div>
            </div>
            <hr />
            <div class="row totals-row">
                <div class="col-7">
                    <strong v-if="excludeCoupons">Price:</strong>
                    <strong v-else>Price with coupon:</strong>
                </div>
                <div class="col-5 text-right">
                    <div class="original-price" v-if="!excludeCoupons">
                        ${{ formatDisplayPrice(estimatedPrice) }}
                    </div>
                    <strong class="text-red">
                        ${{ formatDisplayPrice(priceForDisplay) }}
                    </strong>
                </div>
            </div>
            <client-only>
                <div class="zip-container" v-if="priceForDisplay && !isNaN(priceForDisplay)">
                    or 4 interest-free installments of <strong>$<ZipAmount :amount="priceForDisplay" :useMoneyFormatter="true" /></strong> with <img :src="formatAssetUrl('images/zip-logo.svg')" alt="zip logo - buy now pay later" class="zip-logo">
                </div>
            </client-only>
        </div>
        <div class="order-total-summary-container" v-else-if="estimatedPrice && hasCoatingSelected">
            <client-only>
                <CustomTrustpilotWidget />
            </client-only>
        </div>

        <ConfirmExitModal
            :show="showConfirmExit"
            @close="showConfirmExit = false"
            @confirm="onConfirmExit"
        />
    </div>
</template>

<script>
import BackButton from '@/components/landing-page/lens-replacement/steps-v2/BackButton';
import ZipAmount from '@/components/cart/payment-methods/ZipAmount';
import ConfirmExitModal from '@/components/landing-page/choose-lenses/ConfirmExitModal';
import CustomTrustpilotWidget from '@/components/landing-page/CustomTrustpilotWidget';

import formatMoneyMixin from '@/mixins/money-formatter';
import formatImageUrlMixin from '@/mixins/image-url-formatter';
import summaryMixin from '@/mixins/eyewear-flow-v2/summary-mixin';

import { getLensColorCss, getLensColorShortDisplayName } from '@/utils/eyewear-flow-v2/flow-tree-summary-utils';

export default {
    mixins: [formatMoneyMixin, formatImageUrlMixin, summaryMixin],
    components: {
        BackButton,
        ZipAmount,
        ConfirmExitModal,
        CustomTrustpilotWidget
    },
    data() {
        return {
            showConfirmExit: false,
            toggleSwatch: false,
            animateSwatch: false
        }
    },
    computed: {
        showLensColorSwatch() {
            return this.lensColorCss && this.lensColor;
        },
        lensColor() {
            const selectedColor = this.flowSelections.find(el => el.queryStringKey === 'lensColor');
            if (typeof selectedColor === 'undefined') {
                return '';
            }

            const selectedLensTypeVariationType = this.flowSelections.find(el => el.queryStringKey === 'lensTypeVariation');
            if (typeof selectedLensTypeVariationType === 'undefined') {
                return '';
            }

            const lensColor = getLensColorShortDisplayName(
                this.$store.state.eyewearFlowV2.lensTypeVariations,
                selectedLensTypeVariationType.value,
                selectedColor.value
            );

            if (!lensColor) {
                return '';
            }

            return lensColor;
        },
        lensColorCss() {
            const selectedColor = this.flowSelections.find(el => el.queryStringKey === 'lensColor');
            if (typeof selectedColor === 'undefined') {
                return '';
            }

            const selectedLensTypeVariationType = this.flowSelections.find(el => el.queryStringKey === 'lensTypeVariation');
            if (typeof selectedLensTypeVariationType === 'undefined') {
                return '';
            }

            const lensColorCss = getLensColorCss(
                this.$store.state.eyewearFlowV2.lensTypeVariations,
                selectedLensTypeVariationType.value,
                selectedColor.value
            );

            if (!lensColorCss) {
                return '';
            }

            return lensColorCss;
        },
        hasCoatingSelected() {
            return this.$route?.query?.lensCoating ? true : false;
        },
        productImage() {
            if (!this.baseProduct) {
                return '';
            }

            let availableImages = [this.baseProduct.image];
            if (!this.baseProduct?.customData?.productImages || this.hasCoatingSelected) {
                return availableImages[0];
            }

            for (let x = 0; x < this.baseProduct.customData.productImages.length; x++) {
                availableImages.push(this.baseProduct.customData.productImages[x]);
            }

            let totalImages = availableImages.length;
            let imageIndex = (this.currentStepNumber - 1) % totalImages;

            if (typeof availableImages[imageIndex] !== 'undefined') {
                return availableImages[imageIndex];
            }

            return availableImages[0];
        },
        currentStepNumber() {
            if (!this.currentStep) {
                return 1;
            }

            let counter = 0;
            for (let step of this.steps) {
                counter++;
                if (step.name.toLowerCase() === this.currentStep.toLowerCase()) {
                    return counter;
                }
            }

            return 1;
        },
        currentStep() {
            return this.$store.state.eyewearFlowV2.currentStep;
        },
        baseProduct() {
            return this.$store.state.eyewearFlow.baseProduct;
        },
        priceForDisplay() {
            return this.excludeCoupons ? this.estimatedPrice : this.estimatedPriceWithDiscount;
        },
        excludeCoupons() {
            if (this.baseProduct?.excludeCoupons) {
                return true;
            }

            return this.baseProduct?.frameProduct?.excludeCoupons ? true : false;
        },
        frameName() {
            return this.baseProduct?.customData?.displayName
                ? this.baseProduct.customData.displayName
                : this.baseProduct.name;
        },
        frameNameDetail() {
            let nameComponents = [];
            if (this.baseProduct?.attributes?.frameColor) {
                nameComponents.push(this.baseProduct.attributes.frameColor);
            }

            if (this.baseProduct?.customData?.displaySize) {
                nameComponents.push(this.baseProduct?.customData?.displaySize);
            }

            return nameComponents.join(', ');
        },
    },
    methods: {
        onClickBackToFrame() {
            this.showConfirmExit = true;
        },
        onConfirmExit() {
            this.$router.push({ path: '/' + this.baseProduct.slug });
        }
    },
    watch: {
        showLensColorSwatch(newValue) {
            if (typeof window === 'undefined') {
                this.toggleSwatch = false;
                this.animateSwatch = false;
                return;
            }

            if (!newValue) {
                this.toggleSwatch = false;
                this.animateSwatch = false;
                return;
            }

            this.toggleSwatch = true;
            window.setTimeout(() => {
                this.toggleSwatch = false;
                this.animateSwatch = true;
            }, 50);

            window.setTimeout(() => {
                this.toggleSwatch = false;
                this.animateSwatch = false;
            }, 400);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.product-preview-component {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70%;
    min-width: 550px;
    margin-left: auto;
    min-height: 70vh;
    .product-image-container {
        position: relative;
        max-width: calc(1vw * (530 / 19.2));
        img {
            width: 100%;
        }
        .lens-color-detail-container {
            position: absolute;
            bottom: 20px;
            right: 0px;
            display: flex;
            background-color: #ffffff;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 100px;
            padding: 10px;
            gap: 5px;
            transition: opacity .2s;
            &.animate-swatch {
                opacity: 1;
            }
            &.toggle-swatch {
                opacity: 0;
            }
            .lens-color-swatch {
                border-radius: 100px;
                width: 36px;
                height: 36px;
            }
            small {
                display: block;
                font-size: .875em;
                line-height: 1em;
            }
            strong {
                line-height: 1em;
            }
        }
    }
    .frame-detail-container {
        max-width: calc(1vw * (530 / 19.2));
        text-align: center;
        h3 {
            font-size: 1.5em;
            line-height: 1.2em;
            margin-bottom: 0px;
        }
        small {
            display: block;
            font-size: 1em;
        }
    }
    .order-total-summary-container {
        width: 100%;
        max-width: calc(1vw * (530 / 19.2));
        margin-top: auto;
        .totals-row {
            .original-price {
                display: inline-block;
                text-decoration: line-through;
            }
            strong {
                font-size: 1.3125em;
            }
            .text-red {
                color: #CB1F2B !important;
            }
        }
        .zip-container {
            text-align: right;
            font-size: .875em;
            color: #000000;
            padding-right: 15px;
            .zip-logo {
                display: inline-block;
                vertical-align: top;
                width: 40px;
                margin-top: 0px;
                margin-left: 3px;
            }
        }
    }
    ::v-deep {
        .btn-back-button {
            color: #91a5b4;
            min-width: 150px;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint1) {
    .product-preview-component {
        width: 78%;
        min-width: 490px;
        .product-image-container {
            max-width: calc(1vw * (450 / 15.36));
        }
        .frame-detail-container {
            max-width: calc(1vw * (450 / 15.36));
        }
        .order-total-summary-container {
            max-width: calc(1vw * (450 / 15.36));
        }
    }
}

@media (max-width: $desktopBreakpoint2) {
    .product-preview-component {
        width: 83%;
        .product-image-container {
            max-width: calc(1vw * (450 / 14.4));
        }
        .frame-detail-container {
            max-width: calc(1vw * (450 / 14.4));
        }
        .order-total-summary-container {
            max-width: calc(1vw * (450 / 14.4));
        }
    }
}

@media (max-width: $desktopBreakpoint3) {
    .product-preview-component {
        width: 90%;
        .product-image-container {
            max-width: calc(1vw * (450 / 13.66));
        }
        .frame-detail-container {
            max-width: calc(1vw * (450 / 13.66));
        }
        .order-total-summary-container {
            max-width: calc(1vw * (450 / 13.66));
        }
    }
}

@media (max-width: $desktopBreakpoint4) {
    .product-preview-component {
        min-width: auto;
    }
}
</style>
